import * as React from "react";
import { graphql} from 'gatsby';

export default function NotFound (props) {
    return (
      <div>
          <h1>404</h1>
      </div>
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;